<template>
  <el-dialog
    :title="dialogType == 'add' ? '添加规则' : '编辑规则'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    append-to-body
    width="680px"
  >
    <div style="color: #ff8080;margin-bottom: 25px;">说明：报低价提醒值=最高报价/预估价×（1-允许下浮比例），下浮上限限制。报价小于报低价提醒值时，小程序保存报价给出提醒。</div>
    <el-form ref="form" :model="formData" :rules="rules" label-width="150px" label-position="left">
      <el-form-item label="报价/预估价区间：" required>
        <div style="display:flex">
          <el-form-item label-width="0" prop="lower">
            <el-input v-model="formData.lower" placeholder="请输入起始值" @input="inputNumber($event, formData, 'lower', 0, 99999)" />
          </el-form-item>
          <div style="margin:0 10px">~</div>
          <el-form-item label-width="0" prop="upper">
            <el-input v-model="formData.upper" placeholder="请输入结束值" @input="inputNumber($event, formData, 'upper', 0, 99999)" @blur="computedPrice" />
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="允许下浮比例：" prop="limitationRate">
        <el-input v-model="formData.limitationRate" placeholder="请输入下浮比例" @input="inputNumber($event, formData, 'limitationRate', 2, 99.99)" @blur="computedPrice()">
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <el-form-item label="最多允许下浮：" prop="limitationLimit">
        <el-input v-model="formData.limitationLimit" placeholder="请输入金额" @input="inputNumber($event, formData, 'limitationLimit')"></el-input>
      </el-form-item>
    </el-form>
    
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取 消</el-button>
      <el-button size="small" type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import _api from "@/utils/request";
import { inputNumber } from '@/utils/common'
export default {
  name: "QuoteLimitUpperRuleDia",
  data() {
    const validateRange = (rule, value, callback) => {
      const { field } = rule
      const { lower, upper } = this.formData
      if (!lower || !upper) callback()
      if (Number(lower) >= Number(upper)) {
        if (field == 'lower') callback(new Error('起始值需小于结束值'))
        callback(new Error('结束值需大于起始值'))
      }
      callback()
    }
    const limitLenthVaild = (rule, value, callback) => {
      const number = Number(value)
      if (number < 0 || number > 100) {
        callback(new Error('请输入不大于100的数字'))
      }
      callback()
    }
    const limitPriceVaild = (rule, value, callback) => {
      const number = Number(value)
      const { upper, limitationRate } = this.formData
      if (upper && limitationRate) {
        const maxNumber = Math.floor(upper * limitationRate / 100)
        if (number < 0) {
          callback(new Error('请输入不小于0的数字'))
        }
        if (number > maxNumber) {
          callback(new Error(`不可超过${maxNumber}`))
        }
      }
      callback()
    }
    return {
      visible: false,
      loading: false,

      dialogType: '',
      formData: {
        lower: '',
        upper: '',
        // limitationWarnRate: '',
        // limitationWarnLimit: '',
        limitationRate: '',
        limitationLimit: '',
      },
      rules: {
        lower: [
          { required: true, message: '请输入最低价', trigger: 'blur' },
          { validator: validateRange, trigger: 'blur' }
        ],
        upper: [
          { required: true, message: '请输入最高价', trigger: 'blur' },
          { validator: validateRange, trigger: 'blur' }
        ],
        // limitationWarnRate: [
        //   { required: true, message: '请输入上浮比例', trigger: 'blur' },
        //   { validator: limitLenthVaild, trigger: 'change' }
        // ],
        // limitationWarnLimit: [
        //   { required: true, message: '请输入上限金额', trigger: 'blur' },
        // ],
        limitationRate: [
          { required: true, message: '请输入比例', trigger: 'blur' },
          { validator: limitLenthVaild, trigger: 'change' }
        ],
        limitationLimit: [
          { required: true, message: '请输入金额', trigger: 'blur' },
          { validator: limitPriceVaild, trigger: 'change' }
        ],
      }
    };
  },
  methods: {
    open(row) {
      if (row) {
        this.dialogType = 'edit'
      } else {
        this.dialogType = 'add'
      }
      this.formData = row ? JSON.parse(JSON.stringify(row)) : {
        lower: '',
        upper: '',
        // limitationWarnRate: '',
        // limitationWarnLimit: '',
        limitationRate: '',
        limitationLimit: '',
      }
      this.visible = true
    },
    inputNumber,
    computedPrice() {
      const { upper, limitationRate } = this.formData
      if (upper && limitationRate) this.formData.limitationLimit = Math.floor(upper * limitationRate / 100)
    },
    confirm() {
      this.$refs['form'].validate((vaild) => {
        if (vaild) {
          this.$emit('confirm', this.dialogType, this.formData)
          this.visible = false
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  .des {
    color: #ff8080;
    font-size: 14px;
    font-weight: initial;
  }
}
</style>