<template>
  <div v-loading="loading">
    <div style="margin-bottom: 15px;">
      <div style="color: #ff8080;margin-bottom: 20px;">说明：根据下浮比例计算低价提醒值，回收商保存报价时，若报价＜低价提醒值时，则给出弹窗提示进行修改报价或选择拒绝报价。无法开机不触发低价提醒。</div>
      <div style="margin-bottom: 10px">
        外部订单启用限制
        <el-switch v-model="isBan" active-value="01" inactive-value="02" inactive-color="#bebebe" style="margin-left: 15px"></el-switch>
        <span v-if="isBan == '01'" style="color: #ff8080;margin-left:10px">针对小米、华为、荣耀、立本、龙翔等外部渠道订单</span>
      </div>
      <div style="margin-bottom: 10px">
        繁星订单启用限制
        <el-switch v-model="isKaBan" active-value="01" inactive-value="02" inactive-color="#bebebe" style="margin-left: 15px"></el-switch>
        <span v-if="isKaBan == '01'" style="color: #ff8080;margin-left:10px">仅针对普通订单（不含矩阵映射订单），回收商启用虚拟报价时生效</span>
      </div>
      <div class="biliStyle">
        <div>限制规则</div>
        <el-button size="mini" @click="$refs['quoteLimitLowerRuleDia'].open()" icon="el-icon-plus" type="primary" round>添加规则</el-button>
      </div>
      <el-table border class="mt-20" :data="list" max-height="500px">
        <el-table-column type="index" label="序号" align="center"></el-table-column>
        <el-table-column label="预估价（无预估价时用最高报价）" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.lower }}≤区间＜{{ row.upper }}</span>
          </template>
        </el-table-column>
        <el-table-column label="下浮比例" align="center">
          <template slot-scope="{ row }">
            <span style="color:#0981FF">{{ row.limitationRate }}%</span>
          </template>
        </el-table-column>
        <el-table-column label="下浮上限" align="center">
          <template slot-scope="{ row }">
            {{ row.limitationLimit }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="180">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="$refs['quoteLimitLowerRuleDia'].open(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" @click="delItem(scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <QuoteLimitLowerRuleDia ref="quoteLimitLowerRuleDia" @confirm="ruleChangeHandler" />
  </div>
</template>

<script>
import _api from "@/utils/request";
import QuoteLimitLowerRuleDia from './QuoteLimitLowerRuleDia.vue';
export default {
  components: { QuoteLimitLowerRuleDia },
  name: "UpperLimitTable",
  data() {
    return {
      loading: false,
      regionColumns: [
        { slotName: "region" },
        { slotName: "limitationRate" },
        { slotName: "limitationLimit" },
        { slotName: "operation" },
      ],
      isBan: '02',
      isKaBan: '02',
      list: []
    }
  },
  created() {
    this.getConfig()
  },
  methods: {
    getConfig() {
      this.loading = true
      _api.limitationLowList().then(res => {
        const { isBan, region,isKaBan } = res.data
        this.isBan = isBan
        this.isKaBan = isKaBan
        this.list = region
      }).finally(() => {
        this.loading = false
      })
    },
    ruleChangeHandler(type, ruleItem) {
      if (type == 'add') {
        this.list.push({ ...ruleItem, _t: Math.random(), isBan: '01', isWarn: '01' })
      } else {
        const { id, _t } = ruleItem
        const itemIndex = this.list.findIndex(item => {
          if (id) return item.id === id
          return _t === item._t
        })
        this.$set(this.list, itemIndex, ruleItem)
      }
    },
    delItem(i) {
      this.list.splice(i, 1)
    },
    saveConfig(command, cb) {
      _api.savelimitationLowconfig({
        command,
        isBan: this.isBan,
        isKaBan:this.isKaBan,
        regionList: this.list
      }).then(() => {
        cb()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.biliStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>