<template>
  <el-dialog
    :title="dialogType == 'add' ? '添加规则' : '编辑规则'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    append-to-body
    width="680px"
  >
    <div style="color: #ff8080;margin-bottom: 25px;">说明：报高价提醒值=靓机价×（1+提醒值上浮比例），上浮上限限制。报高价限制值=靓机价×（1+提醒值上浮比例），上浮上限限制。</div>
    <el-form ref="form" :model="formData" :rules="rules" label-width="140px" label-position="left">
      <el-form-item label="靓机价区间：" required>
        <div style="display:flex">
          <el-form-item label-width="0" prop="lower">
            <el-input v-model="formData.lower" placeholder="最低靓机价" @input="inputNumber($event, formData, 'lower')" />
          </el-form-item>
          <div style="margin:0 10px">~</div>
          <el-form-item label-width="0" prop="upper">
            <el-input v-model="formData.upper" placeholder="最高靓机价" @input="inputNumber($event, formData, 'upper')" />
          </el-form-item>
        </div>
      </el-form-item>
      <div class="title">提醒值</div>
      <el-form-item label="上浮报价比例：" prop="limitationWarnRate">
        <el-input v-model="formData.limitationWarnRate" placeholder="请输入上浮报价比例" @input="inputNumber($event, formData, 'limitationWarnRate', 2, 200)">
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <el-form-item label="上浮报价上限：" prop="limitationWarnLimit">
        <el-input v-model="formData.limitationWarnLimit" placeholder="请输入上浮报价上限" @input="inputNumber($event, formData, 'limitationWarnLimit')"></el-input>
      </el-form-item>
      <div class="title">限制值<span class="des">（限制值的上浮比例、上浮上限最低为提醒值的比例和上限）</span></div>
      <el-form-item label="上浮报价比例：" prop="limitationRate">
        <el-input v-model="formData.limitationRate" placeholder="请输入上浮报价比例" @input="inputNumber($event, formData, 'limitationRate', 2)">
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <el-form-item label="上浮报价上限：" prop="limitationLimit">
        <el-input v-model="formData.limitationLimit" placeholder="请输入上浮报价上限" @input="inputNumber($event, formData, 'limitationLimit')"></el-input>
      </el-form-item>
    </el-form>
    
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取 消</el-button>
      <el-button size="small" type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import _api from "@/utils/request";
import { inputNumber } from '@/utils/common'
export default {
  name: "QuoteLimitUpperRuleDia",
  data() {
    const validateRange = (rule, value, callback) => {
      const { field } = rule
      const { lower, upper } = this.formData
      if (!lower || !upper) callback()
      if (Number(lower) >= Number(upper)) {
        if (field == 'lower') callback(new Error('起始值需小于结束值'))
        callback(new Error('结束值需大于起始值'))
      }
      callback()
    }
    const limitLenthVaild = (rule, value, callback) => {
      const number = Number(value)
      if (number < 0 || number > 200) {
        callback(new Error('请输入不大于200的数字'))
      }
      callback()
    }
    const limitRateVaild = (rule, value, callback) => {
      const number = Number(value)
      const { limitationWarnRate } = this.formData
      if (limitationWarnRate && Number(limitationWarnRate) >= number) {
        callback(new Error('限制值上浮比例需大于提醒值上浮比例'))
      }
      callback()
    }
    const limitPriceVaild = (rule, value, callback) => {
      const number = Number(value)
      const { limitationWarnLimit } = this.formData
      if (limitationWarnLimit && Number(limitationWarnLimit) >= number) {
        callback(new Error('限制值上浮上限需大于提醒值上浮上限'))
      }
      callback()
    }
    return {
      visible: false,
      loading: false,

      dialogType: '',
      formData: {
        lower: '',
        upper: '',
        limitationWarnRate: '',
        limitationWarnLimit: '',
        limitationRate: '',
        limitationLimit: '',
      },
      rules: {
        lower: [
          { required: true, message: '请输入最低价', trigger: 'blur' },
          { validator: validateRange, trigger: 'blur' }
        ],
        upper: [
          { required: true, message: '请输入最高价', trigger: 'blur' },
          { validator: validateRange, trigger: 'blur' }
        ],
        limitationWarnRate: [
          { required: true, message: '请输入上浮比例', trigger: 'blur' },
          { validator: limitLenthVaild, trigger: 'change' }
        ],
        limitationWarnLimit: [
          { required: true, message: '请输入上限金额', trigger: 'blur' },
        ],
        limitationRate: [
          { required: true, message: '请输入上浮比例', trigger: 'blur' },
          // { validator: limitLenthVaild, trigger: 'change' },
          { validator: limitRateVaild, trigger: 'change' },
        ],
        limitationLimit: [
          { required: true, message: '请输入上限金额', trigger: 'blur' },
          { validator: limitPriceVaild, trigger: 'change' },
        ],
      }
    };
  },
  methods: {
    open(row) {
      if (row) {
        this.dialogType = 'edit'
      } else {
        this.dialogType = 'add'
      }
      this.formData = row ? JSON.parse(JSON.stringify(row)) : {
        lower: '',
        upper: '',
        limitationWarnRate: '',
        limitationWarnLimit: '',
        limitationRate: '',
        limitationLimit: '',
      }
      this.visible = true
    },
    inputNumber,
    // limitInput(value, name, max) {
    //     let str = (value && value.split("")) || [];
    //     let reg1 = /\d/;
    //     let reg2 = /\./;
    //     // 第一个字符不能为小数点
    //     if (str[0] == ".") {
    //         this.formData[name] = ""
    //         return;
    //     }
    //     // 过滤掉除数字和小数点外的字符
    //     value = str.filter((e) => reg1.test(e) || reg2.test(e));
    //     // 匹配小数点后只能有两位小数
    //     let valJoin = value.join("");
    //     // this.formData[name] =value.replace(/[^0-9]/g,'')
    //     this.formData[name] = valJoin.match(/^\d*(\.?\d{0,2})/g)[0] || "";
    //     if (max && this.formData[name] > max) {
    //       this.formData[name] = max
    //     }
    // },
    confirm() {
      this.$refs['form'].validate((vaild) => {
        if (vaild) {
          this.$emit('confirm', this.dialogType, this.formData)
          this.visible = false
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  .des {
    color: #ff8080;
    font-size: 14px;
    font-weight: initial;
  }
}
</style>