<template>
  <el-dialog :visible.sync="show" title="报价明细" width="30%">
    <!-- 已确认 -->
    <div v-if="isAffirm">
      <div style="display:flex;align-items: center; justify-content: space-between;">
        <div class="deal_detail_title">回收商成交价：{{ data.quotePrice }} </div>
        <!-- data.renewSubsidyPrice != '--' && data.renewSubsidyPrice != 0  -->
        <div class="deal_detail_title1" v-if="false">回收金额（含补贴）：{{ data.recyclePrice }} <span class="deal_detail_title2">（含{{data.renewSubsidyPrice}}元换新补贴）</span> </div>
      </div>  
      <div class="deal_detail_inner">
        <div class="deal_detail_line-title">
          回收商实收价（扣除差异）：{{ data.netQuotePrice }}
        </div>
        <div class="deal_detail_line-title">
          差异金额 ：<span class="deal_detail_line-tips-red">-{{ data.depreciatePrice }}</span>
        </div>
      </div>
      <div class="deal_detail_title">
        订单总利润：{{ data.orderRebate }}
      </div>
      <div class="deal_detail_inner">
        <div class="deal_detail_line">
          <p class="deal_detail_line-title">
            公司返利：{{ data.allAddPrice }}
          </p>
          <p class="deal_detail_line-tips">
            <span class="deal_detail_line-tips-blue">+门店返利：{{data.storeAddPrice}}；+预留返利：{{ data.delayAddPrice }}；</span>
            <span class="deal_detail_line-tips-red">-加价挪用：{{ data.useAddPrice }}</span>
          </p>
        </div>
        <div v-if="data.manageRewardList && data.manageRewardList.length" class="deal_detail_line">
          <p class="deal_detail_line-title">
            管理人员提成：{{ data.managerReward }}
          </p>
          <div v-for="item in data.manageRewardList" :key="item.staffId" class="deal_detail_line-tips">
            <p>--{{item.staffName}}（{{item.staffType | getStaff(item.isMajor)}}）：{{item.reward}}</p>
          </div>
        </div>
        <div class="deal_detail_line">
          <p class="deal_detail_line-title">
            员工红包：{{ data.angPow }}
          </p>
          <p class="deal_detail_line-tips">
            --店长：实得红包：{{ data.angPowSmStaff }}
            <span class="deal_detail_line-tips-blue" style="margin-left: 16px;">+计算红包：{{data.orderAngPowSmStaff}}；</span>
            <span style="margin-left: 10px;" class="deal_detail_line-tips-red">-挪用红包{{data.usdAngPowSmStaff }}</span>
          </p>
          <p class="deal_detail_line-tips">
            --店员：实得红包：{{ data.angPowStaff}}
            <span class="deal_detail_line-tips-blue" style="margin-left: 16px;">+计算红包：{{data.orderAngPowStaff }}；</span>
            <span class="deal_detail_line-tips-red" style="margin-left: 10px;">-挪用红包{{ data.usdAngPowStaff }}</span>
          </p>
        </div>
        <div class="deal_detail_line">
          <p class="deal_detail_line-title">
            门店压价金额：{{ data.orderCutPrice }}
          </p>
          <p class="deal_detail_line-tips">
            --常规压价金额：{{ data.normalCutPrice }}；外部压价金额：{{ data.outCutPrice }}
          </p>
          <p class="deal_detail_line-tips">
            --公司压价返利：{{data.companyRebate}}；店长压价提成：{{ data.cutPriceSmStaff }}；店员压价提成：{{ data.cutPriceStaff }}
          </p>
        </div>
        <div class="deal_detail_line">
          <p class="deal_detail_line-title">
            员工创收奖励（未加价提成）：{{data.addPriceToStaff}}
          </p>
        </div>
        <div class="deal_detail_line">
          <p class="deal_detail_line-title">
            线上回收员工分享奖励：{{ data.miniMallShareReward }}
          </p>
        </div>
      </div>
      <div style="display:flex;align-items: center; justify-content: space-between;">
        <div class="deal_detail_title"> 顾客成交价：{{ data.userPrice }}</div>
        <div class="deal_detail_title1" v-if="data.renewSubsidyPrice != '--'  && data.renewSubsidyPrice != 0&&data.renewSubsidyWay=='00'" >补贴后顾客成交价：{{ data.userPriceSubsidy }} <span class="deal_detail_title2">（含{{data.renewSubsidyPrice}}元换新补贴）</span> </div>
      </div> 
    </div>
    <!-- 未确认 -->
    <div v-else>
      <div class="deal_detail_title">
        回收商报价：{{ data.quotePrice }}
      </div>
      <div class="deal_detail_title">
        订单总利润：{{ data.orderRebate }}
      </div>
      <div class="deal_detail_inner">
        <div class="deal_detail_line">
          <p class="deal_detail_line-title">
            公司返利：{{ data.allAddPrice }}
          </p>
        </div>
        <div v-if="data.manageRewardList && data.manageRewardList.length" class="deal_detail_line">
          <p class="deal_detail_line-title">
            管理人员提成：{{ data.managerReward }}
          </p>
          <div v-for="item in data.manageRewardList" :key="item.staffId" class="deal_detail_line-tips">
            <p>--{{item.staffName}}（{{item.staffType | getStaff(item.isMajor)}}）：{{item.reward}}</p>
          </div>
        </div>
        <div class="deal_detail_line">
          <p class="deal_detail_line-title">
            员工红包：{{ data.angPow }}
          </p>
          <p class="deal_detail_line-tips">
            --店长：应得红包：{{ data.angPowSmStaff }}
            <span class="deal_detail_line-tips-blue" style="margin-left: 16px;">+设置红包：{{data.orderAngPowSmStaff }}</span>
            <span style="margin-left: 10px;" class="deal_detail_line-tips-red">-挪用金额{{ data.usdAngPowSmStaff }}</span>
          </p>
          <p class="deal_detail_line-tips">
            --店员：应得红包：{{ data.angPowStaff }}
            <span class="deal_detail_line-tips-blue" style="margin-left: 16px;">+设置红包：{{data.orderAngPowStaff }}</span>
            <span class="deal_detail_line-tips-red" style="margin-left: 10px;">-挪用金额{{ data.usdAngPowStaff }}</span>
          </p>
        </div>
        <div class="deal_detail_line">
          <p class="deal_detail_line-title">
            门店压价金额：{{ data.orderCutPrice }}
          </p>
        </div>
        <div class="deal_detail_line" style="margin-bottom: 0">
          <p class="deal_detail_line-title">
            线上回收分享奖励：{{ data.miniMallShareReward }}
          </p>
        </div>
      </div>
      <div class="deal_detail_title">
        顾客成交价：{{ data.userPrice }}
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="show = false">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import _api from "@/utils/request";
const staffTypes =[
  { label: '创建人', value: '01'},
  { label: '总监', value: '02'},
  { label: '门店店长', value: '03'},
  { label: '普通职员', value: '04'},
]
export default {
  filters: {
    getStaff(v, isMajor) {
      if(v == '01') {
        if(isMajor) return '创建人'
        return '子账号'
      }
      return staffTypes.find(item => item.value == v)?.label
    }
  },
  data() {
    return {
      show: false,
      isAffirm: false,
      data: {},
      
    }
  },
  methods: {
    async open(orderNo, state) {
      const data = await this.getData(orderNo)
      this.data = data
      // 待确认和已取消代表未确认，返回false，其他作为true
      this.isAffirm = ["00", "10"].indexOf(state) < 0
      this.show = true
    },
    async getData(orderNo) {
      return await _api.getQuoteCompute(orderNo).then((r) => {
        const data = r.data
        return {
          ...data,
          // 回收商实收价
          netQuotePrice: this.computedMoney(
            data.quotePrice,
            data.depreciatePrice,
            false
          ),
          // 员工红包
          angPow: this.computedMoney(data.angPowSmStaff, data.angPowStaff),
          // 公司压价返利
          companyRebate: this.computedMoney(
            data.orderCutPrice,
            data.allStaffCutPrice,
            false
          ),
        };
      });
    },

    // 数字转换
    convertYypeNumer(num) {
      let v = num;
      // 是否类型正确
      let isType = true;
      if (typeof v !== "number") {
        isType = false;
        v = Number(v);
        if (isNaN(v)) {
          v = 0;
        }
      }
      return {
        isType,
        v,
      };
    },

    // 计算金额值
    computedMoney(num1, num2, isAdd = true) {
      const v1 = this.convertYypeNumer(num1);
      let v2 = this.convertYypeNumer(num2);
      if (!v1.isType && !v2.isType) {
        return "--";
      }
      v1.v = Math.abs(v1.v);
      v2.v = Math.abs(v2.v);

      return isAdd ? (v1.v + v2.v).toFixed(2) : (v1.v - v2.v).toFixed(2);
    }
  }
}
</script>

<style lang="scss" scoped>

.deal_detail_title {
  // width: 100%;
  padding-left: 16px;
  font-size: 14px;
  font-weight: 900;
  color: #000;
  position: relative;
  margin-bottom: 10px;
  flex:1;

  &::after {
    content: "";
    width: 8px;
    height: 8px;
    background: #0c82ff;
    position: absolute;
    left: 0%;
    top: 50%;
    transform: translateY(-50%);
  }
}
.deal_detail_title1 {
  // width: 100%;
  padding-left: 16px;
  font-size: 14px;
  font-weight: 900;
  color: #000;
  position: relative;
  margin-bottom: 10px;
}

.deal_detail_title2 {
  font-size: 14px;
  color: #FF0000;
}
.deal_detail_inner {
  margin-bottom: 20px;
  background: #f2f2f2;
  font-size: 12px;
  color: #111;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 6px;
}

.deal_detail_line {
  margin-bottom: 16px;
}

.deal_detail_line-title {
  padding-left: 10px;
  position: relative;
  margin-bottom: 6px;
  font-weight: 600;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 5px;
    height: 5px;
    background: #000;
    border-radius: 50%;
    transform: translateY(-50%);
  }
}

.deal_detail_line-tips {
  transform: translateX(-5px);
}

.deal_detail_line-tips-blue {
  color: #0981fe;
}

.deal_detail_line-tips-red {
  color: #ff0000;
}
</style>