<template>
  <div>
    <el-dialog title="报价限制配置" :visible.sync="visible" :close-on-click-modal="false" width="1000px">
      <div class="class_type" style="margin-bottom: 20px">
        <el-radio-group v-model="tab">
          <el-radio-button label="upper">报高价限制</el-radio-button>
          <el-radio-button label="lower">报低价限制</el-radio-button>
        </el-radio-group>
      </div>
      <UpperLimitTable ref="upperLimitTable" v-if="tab === 'upper'" />
      <LowerLimitTable ref="lowerLimitTable" v-if="tab === 'lower'" />
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="visible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="command = ''; yajiaShow = true;">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog class="acc_dialog" title="提示" :visible.sync="yajiaShow" :close-on-click-modal="false" width="500px">
      <div style="margin-bottom: 20px; font-weight: 500 ;text-align: center;font-size: 18px;">
        是否确认修改报价限制配置？
      </div>
      <div class="command">
        <div>动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="yajiaShow = false">取消</el-button>
        <el-button type="primary" @click.native="confirm">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import LowerLimitTable from './LowerLimitTable.vue'
import UpperLimitTable from './UpperLimitTable.vue'
export default {
  components: { LowerLimitTable, UpperLimitTable },
  name: "QuoteLimitSettingDia",
  data() {
    return {
      visible: false,
      tab: '',

      yajiaShow: false,
      command: ''
    }
  },
  methods: {
    open() {
      this.tab = 'upper'
      this.visible = true
    },
    confirm() {
      const tableRef = this.tab == 'upper' ? this.$refs['upperLimitTable'] : this.$refs['lowerLimitTable']
      tableRef.saveConfig(this.command, () => {
        this.$message.success('保存成功！')
        this.tab = ''
        this.visible = false
        this.yajiaShow = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.command {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.command .el-input {
  width: 80%;
}
</style>